<template>
    <button class="close-btn" type="button" v-bind="$attrs" role="button">
				<lord-icon
						v-if="animated"
						:icon="animated"
						trigger="hover"
						colors="primary:#ffffff"
						:state="state"
						target=".fp-icon-button"
				></lord-icon>
        <svg v-else width="17" height="17" viewBox="0 0 17 17" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 15.8488C0.105316 15.6613 0 15.407 0 15.1418C0 14.8766 0.105316 14.6223 0.292787 14.4348L14.4348 0.292789C14.6234 0.110631 14.876 0.00983671 15.1382 0.0121151C15.4004 0.0143936 15.6512 0.119563 15.8366 0.304971C16.022 0.490379 16.1272 0.74119 16.1295 1.00339C16.1317 1.26558 16.0309 1.51819 15.8488 1.70679L1.70679 15.8488C1.51926 16.0363 1.26495 16.1416 0.999787 16.1416C0.734623 16.1416 0.480314 16.0363 0.292787 15.8488Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 0.292787C0.480314 0.105316 0.734623 0 0.999787 0C1.26495 0 1.51926 0.105316 1.70679 0.292787L15.8488 14.4348C16.0309 14.6234 16.1317 14.876 16.1295 15.1382C16.1272 15.4004 16.022 15.6512 15.8366 15.8366C15.6512 16.022 15.4004 16.1272 15.1382 16.1295C14.876 16.1317 14.6234 16.0309 14.4348 15.8488L0.292787 1.70679C0.105316 1.51926 0 1.26495 0 0.999787C0 0.734623 0.105316 0.480314 0.292787 0.292787Z"/>
        </svg>
    </button>
</template>

<script>
export default {
    name: "CloseBtn",
    props: {
				color: {
						required: false
				},
				animated: {
						required: false,
						type: String,
						default: null
				},
				state: {
						type: String,
						default: 'hover'
				},
		},
    data(){
        return {
            fillColor: '#fff'
        }
    },
    mounted() {
        if(this.color){
            this.fillColor = this.color
        }
    }
}
</script>

<style scoped lang="scss">

</style>
