<template>
    <div class="page scroll-gallery">
				<transition appear>
					<scroll-gallery v-if="postType === 'scroll-gallery' && post" :content="post"></scroll-gallery>
				</transition>
		</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ScrollGallery from "@/components/ScrollGallery";

export default {
    name: "ScrollGalleryPage",
		components: {ScrollGallery},
		computed: {
				post(){
						if(this.contentPage){
								return this.contentPage
						}
						if(this.pages && this.pages[this.postId]){
								return this.pages[this.postId]
						}
						return null
				},
				postType(){
						if(this.post && this.post.post_type){
								return this.post.post_type
						}
						return 'content'
				},
				...mapState(['pages']),
				...mapGetters(['contentPage'])
		},
		data(){
				return {
						postId: null
				}
		},
		methods: {
				async loadContent(to){
						const postId = to.params.postId
						this.postId = postId
						if(postId){
								await this.$store.dispatch('loadContent', {postId, type: 'scroll-gallery'})
						}
				},
				close(){
						this.$router.push({name: 'home'})
				}
		},
		beforeRouteEnter(to, from, next){
				next(vm => {
						vm.loadContent(to)
				})
		},
		beforeRouteUpdate(to){
				this.loadContent(to)
		}
}
</script>

<style scoped>

</style>
