<template>
	<div class="apartments-table scrollable-y">
			<table :class="{uadc: useApartmentDetailsControl}">
					<thead>
							<template v-if="useApartmentDetailsControl && apartmentsTableColumns && apartmentsTableColumns.length">
							<tr>
									<th>{{ $t('שם') }}</th>
									<th
										v-for="(aptColumn, aptColumnIndex) in apartmentsTableColumns"
										:key="aptColumnIndex"
										:title="titleDisplay(aptColumn)"
									>
										<a
											v-if="aptColumn === 'favorites'"
											href="javascript:"
											@click="navigateToFavorites"
										>{{$t('מועדפים')}}</a>
										<span>{{ titleDisplay(aptColumn) }}</span>
									</th>
									<th v-if="showCompare">{{$t('השוואה')}}</th>
							</tr>
							</template>
							<template v-else>
									<tr>
											<th :title="$t('דגם')"><fp-icon symbol="buildings-3d"></fp-icon></th>
											<th :title="$t('קומה')"><fp-icon symbol="stairs"></fp-icon></th>
											<th :title="$t('חדרים')"><fp-icon symbol="open-door"></fp-icon></th>
											<th :title="$t('מ&quot;ר דירה')"><fp-icon symbol="roller"></fp-icon></th>
											<th :title="$t('מ&quot;ר מרפסת')"><fp-icon symbol="porch"></fp-icon></th>
											<th v-if="showCompare" :title="$t('השוואה')"><fp-icon symbol="compare"></fp-icon></th>
									</tr>
							</template>
					</thead>
					<tbody v-if="loading">
					<tr>
							<td colspan="6">
									<loading-data></loading-data>
							</td>
					</tr>
					</tbody>
					<tbody v-else>
					<template v-if="useApartmentDetailsControl && apartmentsTableColumns && apartmentsTableColumns.length">
							<tr
								v-for="(apt, aptI) in apartments"
								:key="aptI"
								:class="{active: currentAptId === apt.id}"
								tabindex="0"
								@click="goToApartmentPage(apt, aptI)"
								@mouseenter="apartmentHover(apt)"
							>
									<td><apartment-title :apartment="apt" :show-favs-button="false"></apartment-title></td>
									<td
											v-for="(aptColumn, aptColumnIndex) in apartmentsTableColumns"
											:key="aptColumnIndex"
											:title="aptDetail(apt, aptColumn)"
									>
											<template v-if="aptColumn === 'available'">
													<apartment-availability :available="apt.available"></apartment-availability>
											</template>
											<template v-if="aptColumn === 'favorites'">
													<apartment-fav-button :apt-id="apt.id" :show-link-to-favs="false"></apartment-fav-button>
											</template>
											<span v-else>{{ aptDetail(apt, aptColumn) }}</span>
									</td>
									<td v-if="showCompare">
											<fp-checkbox v-if="compare_enabled" v-model="apartmentsCompare[apt.id]" @click.stop></fp-checkbox>
									</td>
							</tr>
					</template>
					<template v-else>
							<tr
								v-for="(apt, aptI) in apartments"
								:key="aptI"
								:class="{active: currentAptId === apt.id}"
								tabindex="0"
								@click="goToApartmentPage(apt, aptI)"
								@mouseover="apartmentHover(apt)"
							>
									<td><apartment-title :apartment="apt" :show-favs-button="false"></apartment-title></td>
									<td>
											<span v-if="apt.floor_name">{{apt.floor_name}}</span>
											<span v-else>{{apt.floor}}</span>
									</td>
									<td>
											<span v-if="apt.type_rooms">{{apt.type_rooms}}</span>
									</td>
									<td>
											<span v-if="apt.type_dimensions">
													<span v-if="apt.type_dimensions.apartment_sm">{{apt.type_dimensions.apartment_sm}}</span>
											</span>
									</td>
									<td>
											<span v-if="apt.type_dimensions">
												<span v-if="apt.type_dimensions.porch_sm">{{apt.type_dimensions.porch_sm}}</span>
											</span>
									</td>
									<td v-if="showCompare">
											<fp-checkbox v-if="compare_enabled" v-model="apartmentsCompare[apt.id]" @click.stop></fp-checkbox>
									</td>
							</tr>
					</template>
					</tbody>
			</table>
			<div v-if="hasCompare" style="margin-top: 15px; text-align: center">
					<fp-button type="button" @click="doCompression" variant="primary-outline">{{$t('השוואת דירות')}}</fp-button>
			</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import FpCheckbox from "@/components/buttons/FpCheckbox"
import FpButton from "@/components/buttons/FpButton"
import FpIcon from "@/components/FpIcon"
import LoadingData from "@/components/LoadingData"
import ApartmentTitle from "@/components/apartment/ApartmentTitle.vue"
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability.vue"
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton.vue"
import numeral from "numeral"

export default {
		name: "ApartmentsTable",
		components: {ApartmentFavButton, ApartmentAvailability, ApartmentTitle, LoadingData, FpIcon, FpButton, FpCheckbox},
		props: {
				apartments: {
						type: [Array, Object],
						default: null
				},
				currentApt: {
						default: null
				},
				loading: {
						default: false,
						type: Boolean
				},
				showCompare: {
						default: true,
						type: Boolean
				}
		},
		computed: {
				apartment_name_format(){
						if(this.filterOptions && this.filterOptions.apartment_name_format){
								return this.$store.getters.settings.apartments_filter.options.apartment_name_format
						}
						return null
				},
				compare_enabled(){
						if(this.filterOptions && this.filterOptions.compare_enabled){
								return this.$store.getters.settings.apartments_filter.options.compare_enabled
						}
						return null
				},
				hasCompare(){
						if(this.compare_enabled){
								if(this.apartmentsCompare){
										const keys = Object.keys(this.apartmentsCompare).filter(a => this.apartmentsCompare[a])
										return keys && keys.length > 1
								}
						}
						return null
				},
				apartmentsTableColumns(){
						if(this.apartmentDataView && this.apartmentDataView.apartments_table_columns){
								return this.apartmentDataView.apartments_table_columns
						}
						return null
				},
				importTableHeaders(){
						if(this.apartmentDataView && this.apartmentDataView.import_table_headers){
								return this.apartmentDataView.import_table_headers
						}
						return null
				},
				titlesBySlug(){
						if(this.importTableHeaders){
								const headers = {};
								Object.keys(this.importTableHeaders).forEach(ithKey => {
										const ith = this.importTableHeaders[ithKey]
										headers[ith.slug] = ith.title
								})
								return headers
						}
						return []
				},
				useApartmentDetailsControl(){
						if(this.apartmentDataView && this.apartmentDataView.use_apartment_details_control){
								return this.apartmentDataView.use_apartment_details_control
						}
						return false
				},
				...mapGetters(['apartmentPageEnabled', 'filterOptions', 'currentProjectId','apartmentDataView'])
		},
		watch: {
				currentApt(newVal){
						if(newVal && newVal.id){
								this.currentAptId = newVal.id
						}else{
								this.currentAptId = null
						}
				},
				apartmentsCompare: {
						handler(newVal){
								this.$store.commit('apartmentsCompare', newVal)
						},
						deep: true
				}
		},
		data(){
				return {
						currentAptId: null,
						apartmentsCompare: {}
				}
		},
		methods: {
				navigateToFavorites(){
						this.$router.push(`/projects/${this.currentProjectId}/favorites`)
				},
				titleDisplay(slug){
						if(this.titlesBySlug && this.titlesBySlug[slug]){
								return this.titlesBySlug[slug]
						}
						return ''
				},
				aptDetail(apt, slug){
						if(slug === 'floor'){
								return apt.floor_name
						}
						if(slug === 'apartment_type'){
								return apt.type
						}
						/*else if(slug === 'flat_area' && apt.type_dimensions && apt.type_dimensions.apartment_sm){
								return apt.type_dimensions.apartment_sm
						}else if(slug === 'basement_area' && apt.type_dimensions && apt.type_dimensions.porch_sm){
								return apt.type_dimensions.porch_sm
						}*/
						const details = this.aptMoreDetailsBySlug(apt)
						if(details && details[slug]){
								return this.formatNumeric(details[slug], slug)
						}
						return ''
				},
				formatNumeric(value, slug){
						if(slug.indexOf('price') !== -1 || slug.indexOf('cost') !== -1 || slug.indexOf('fee') !== -1 || slug.indexOf('tax') !== -1){
								const num = numeral(value).format('0,0')
								return `₪${num}`
						}
						return value;
				},
				aptMoreDetailsBySlug(apt){
						if(apt && apt.more_details){
								const md = {}
								apt.more_details.forEach(mdItem => {
										if(mdItem.column_name && mdItem.value){
												md[mdItem.column_name] = mdItem.value
										}
								})
								return md
						}
						return null
				},
				aptTitle(apt){
						let titleTemplate = this.apartment_name_format
						if(!titleTemplate){
								return null
						}
						const variables = [
								"דירה",
								"טיפוס",
								"בניין",
								"חדרים",
								"קומה",
								"שכונה",
								"מגרש",
						]
						variables.forEach((variable) => {
								let text = null;
								switch (variable){
										case "דירה":
												text = apt.title
												break
										case "טיפוס":
												text = apt.type
												break
										case "בניין":
												text = apt.type_building ? apt.type_building.name : null
												break
										case "חדרים":
												text = apt.type_rooms
												break
										case "קומה":
												text = apt.floor
												break
										case "שכונה":
												text = apt.type_quarter ? apt.type_quarter.name : null
												break
										case "מגרש":
												text = apt.type_plot ? apt.type_plot.name : null
												break
								}

								if(text !== null){
										text = `${text}`
										const rex = new RegExp(`\\[${variable}]`, 'g')
										titleTemplate = titleTemplate.replace(rex,   `${text}`)
								}
						})
						return titleTemplate
				},
				goToApartmentPage(apt, index){
						if(this.aptIsClickable(apt)){
								this.$emit('apartment-clicked', apt, index)
						}
				},
				apartmentHover(apt){
						this.$frameBus.emit('message', {
								action: 'hover-apartment',
								apartment: apt
						})
				},
				aptIsClickable(apt){
						return this.apartmentPageEnabled && apt.type_show_page
				},
				async doCompression(){
						if(this.compare_enabled){
								await this.$router.push({name: 'apartments-compare', params: {projectId: this.currentProjectId}})
						}
				},
		},
		mounted() {
				this.apartmentsCompare = JSON.parse(JSON.stringify(this.$store.getters.apartmentsCompare))
		}
}
</script>

<style scoped lang="scss">
.apartments-table {
		backdrop-filter: blur(5px);
		background-color: rgba(0, 0, 0, 0.8);

		table {
				width: 100%;
				position: relative;

				tr {
						cursor: pointer;
				}

				td, th {
						padding: 8px 5px;
						text-align: center;
				}

				th:first-child, td:first-child {
						text-align: start;
				}

				thead {
						position: sticky;
						top: 0;
				}

				tr:hover td,
				tr.active td,
				tr:focus td {
						background-color: rgba(120, 120, 120, 0.51);
				}

				&.uadc {
						th {
								font-size: .8rem;
						}

						th,
						td:not(:first-child) {
								max-width: 88px;
						}

						thead {
								background-color: rgba(0,0,0,.5);
								backdrop-filter: blur(5px);
						}
				}
		}

}
</style>
