import axios from "axios";
import store from "@/store";
//import i18n from "@/i18n";

let baseUrl = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_API_PATH
const appUrl = `${window.location.protocol}//${window.location.host}/wp-content/plugins/mooz360-fp/floorplan-app/`
if(window.location && window.location.host && window.location.host.indexOf('localhost') === -1){
    baseUrl = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_API_PATH}`
}

export {baseUrl, appUrl};

const http = axios.create({baseURL: baseUrl});

http.interceptors.request.use((config) => {
    // console.log('request sent', config)
    // console.log(store.state)
    if(store.state.accessToken){
        config.headers.Authorization = `Bearer ${store.state.accessToken}`
    }
    /*if(store.state.user && store.state.user.ID){
        config.headers['fp-ui'] = store.state.user.ID
    }*/
    /*let locale = 'he'
    if (i18n.mode === 'legacy') {
        locale = i18n.global.locale
    } else if(i18n.global.locale?.value) {
        locale = i18n.global.locale.value
    }
    if(!config.params){
        config.params = {}
    }
    config.params['wpml_language'] = locale*/
    return config
},(error) => {
    store.commit('globalLoading', 0)
    return error;
})

http.interceptors.response.use((response) => {
    return response
}, (error) => {
    store.commit('globalLoading', 0)
    console.warn(error)
    return error.response
})

export default http
