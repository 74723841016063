<template>
    <router-link class="main-logo" to="/">
        <img v-if="logoSrc" class="logo-img" :src="logoSrc" :alt="siteName">
        <div v-else class="site-name">{{siteName}}</div>
    </router-link>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "MainLogo",
    computed: {
        siteName(){
            return this.settings && this.settings.site_name ? this.settings.site_name : 'LOGO'
        },
        logoSrc(){
            return this.settings && this.settings.logo ? this.settings.logo : null
        },
        ...mapState(['settings'])
    }
}
</script>

<style scoped lang="scss">
.site-name {
    margin-right: auto;
    text-transform: uppercase;
}
img.logo-img {
    align-self: flex-end;
    margin-right: auto;
}

.is-mobile {
    img.logo-img {
        max-height: 50px;
        width: auto;
    }
}
</style>
