<template>
    <div class="project-modal scrollable-y">
        <close-btn @click.stop="close"></close-btn>
        <div class="modal-header" v-if="project">
            <div class="modal-title" v-if="useInnerTitle && project.inner_name !== 'undefined'">{{project.inner_name}}</div>
            <div class="modal-title" v-else>{{project.name}}</div>
        </div>
        <div class="modal-content">
            <template v-if="project">
								<div class="scrollable-y">
										<div class="project-content" v-html="project.content"></div>
										<div class="project-icons" v-if="project.icons && project.icons.length">
												<span v-for="(icon, iconIndex) in project.icons" :key="iconIndex">
														<img v-if="icon.icon" :src="icon.icon" :alt="icon.label">
														<span>{{icon.label}}</span>
												</span>
										</div>
								</div>
                <div class="project-buttons" v-if="project.buttons && project.buttons.length">
                    <fp-button class="active-icon"
                               v-for="(button, bIndex) in project.buttons"
                               :key="bIndex"
                               :class="{active: isCurrent(button)}"
                               variant="primary-outline"
                               @click="buttonClicked(button)"
                    >{{button.label}}</fp-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import FpButton from "@/components/buttons/FpButton";
import CloseBtn from "@/components/buttons/CloseBtn";
import {mapGetters} from "vuex";

export default {
    name: "ProjectModal",
    components: {CloseBtn, FpButton},
    props: {
        project: {
            type: Object
        },
        currentButton: {
            type: Object
        }
    },
    computed:{
        useInnerTitle(){
            if(this.$store.getters.settings && this.$store.getters.settings.projects){
                return this.$store.getters.settings.projects.use_inner_title
            }
            return false
        },
				...mapGetters(['isMobile'])
    },
    methods: {
        close(){
            this.$store.commit('toggleProjectModal', false)
            setTimeout(() => {
                this.$router.push('/')
            }, 500)
        },
        buttonClicked(button){
            this.$emit('button-clicked', button)
        },
        isCurrent(button){
            return JSON.stringify(button) === JSON.stringify(this.currentButton)
        }
    },
    mounted() {
        if(!this.isMobile && this.project && this.project.buttons && this.project.buttons[0]){
            this.buttonClicked(this.project.buttons[0])
        }
    }
}
</script>

<style scoped lang="scss">
    .project-modal {
        position: relative;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
        border: 1px solid #FFFFFF;
        padding: 0.9375rem;
        border-radius: 5px;

        &.init-hidden {
            display: none;
        }

        .modal-header {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 10px;
            min-height: 1.125rem;
        }

        .modal-content {
            line-height: 1.2;
        }

        .close-btn {
            position: absolute;
            top: 0.9375rem;
            left: 0.9375rem;
        }

        .project-icons {
            display: flex;
            flex-flow: row wrap;
            padding: 1.25rem;
            gap: 2.8125rem;
            align-items: flex-start;

            > span {
                flex-basis: 8.3125rem;
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                gap: 0.625rem;
            }
        }

        .project-content {
            font-size: 1.125rem;

            &:deep(p) {
                margin-bottom: 0.9375rem;
            }
        }

        .project-buttons {
            padding: 1.25rem 0;
            border-top: solid 1px #818181;
						display: grid;
						grid-template-columns: 1fr 1fr;
            gap: .75rem;

            &:deep(button) {
                min-height: 3rem;
                border-radius: 4px;
                font-size: 1rem;
								line-height: 1;
            }
        }
    }

    .is-mobile {
        .project-modal {
            .project-icons {
                padding: 1.25rem 0;
                gap: 1rem;

                > span {
                    flex-basis: calc(50% - .5rem);
                }
            }
        }
    }

		.is-desktop,
		.is-tablet {
				.project-modal {
						display: flex;
						flex-flow: column;

						.modal-content {
								display: flex;
								flex-flow: column;
								flex-grow: 1;
						}
				}
		}

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.project-modal {
						max-height: none;
						position: relative;
						background-color: transparent;
						background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 80%,rgb(0 0 0 / 90%) 100%);
						backdrop-filter: blur(5px);
						border: none;
						border-radius: 0;
						height: 100vh;
				}
		}

		.ltr {
				.close-btn {
						right: 0.9375rem;
						left: auto;
				}
		}

</style>
