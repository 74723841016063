<template>
    <div class="loading-data">
        <span v-if="this.$slots.default">
            <slot></slot>
        </span>
        <span v-else>{{$t('טוען')}}</span>
    </div>
</template>

<script>
export default {
    name: "LoadingData"
}
</script>

<style scoped lang="scss">
    .loading-data {
        padding: 1rem;
        margin: auto;
        span{
            position: relative;
            line-height: 1;

            &::after {
                position: absolute;
                right: 100%;
                bottom: 2px;
                animation: loadingDots .6s linear infinite;
                content: "";
            }
        }
    }
    @keyframes loadingDots {
        25% {
            content: ".";
        }
        50% {
            content: "..";
        }
        75% {
            content: "...";
        }
        100% {
          content: "";
      }
    }
</style>
