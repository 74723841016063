<template>
    <div class="entrepreneur-info" v-for="entrepreneur in entrepreneurs" :key="entrepreneur.ID">
        <div v-for="(section, sectionIndex) in sections(entrepreneur)" :key="sectionIndex" class="flex flex-sm-column" :class="[sectionIndex % 2 === 0 ? 'flex-row-reverse' : 'flex-reverse']">
            <template v-if="isMobile">
                <div class="experience-data" v-if="section.experience && section.experience.length">
                    <template v-for="(xp, xpInd) in section.experience" :key="xpInd">
                        <div class="data-box">
                            <div class="data">{{xp.data}}</div>
                            <div class="name">{{xp.name}}</div>
                        </div>
                    </template>
                </div>
            </template>
						<slider-gallery
								v-if="section.images_slider && section.images_slider.length"
								class="entr-gallery"
								:slides="section.images_slider"
								:auto-height="false"
								height="50vh"
								:show-arrows="false"
								:show-disclaimer="false"
						>
								<template v-slot="{slide}">
									<a :href="slide.url" target="_blank">
											<img :src="slide.sizes.large" :alt="slide.alt">
									</a>
								</template>
						</slider-gallery>
            <div class="entr-thumb" v-else-if="section.thumbnail">
                <img :src="section.thumbnail" :alt="section.title">
            </div>
            <div class="px-1">
                <template v-if="!isMobile">
                    <div class="experience-data" v-if="section.experience && section.experience.length">
                        <template v-for="(xp, xpInd) in section.experience" :key="xpInd">
                            <div class="data-box">
                                <div class="data">{{xp.data}}</div>
                                <div class="name">{{xp.name}}</div>
                            </div>
                            <div class="data-sep"></div>
                        </template>
                    </div>
                </template>
                <h1 class="entrepreneur-title">{{section.title}}</h1>
                <div class="entrepreneur-about" v-html="section.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SliderGallery from "@/components/SliderGallery";

export default {
    name: "EntrepreneurInfo",
		components: {SliderGallery},
		props: {
        entrepreneurs: {
            required: false,
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
				...mapGetters(['isMobile'])
    },
		methods: {
				sections(entrepreneur){
						const sections = []
						if(entrepreneur){
								sections.push({
										content: entrepreneur.content,
										title: entrepreneur.title,
										images_slider: entrepreneur.images_slider,
										experience: entrepreneur.experience,
										thumbnail: entrepreneur.thumbnail,
								})
								if(entrepreneur.additional_content){
										entrepreneur.additional_content.forEach(ac => sections.push(ac))
								}
						}
						return sections
				}
		}
}
</script>

<style scoped lang="scss">
.entrepreneur-info {
    margin: 0.9375rem;

    .entrepreneur-title {
        margin: 1.6875rem 0;
        font-size: 1.5625rem;
        font-weight: 700;
    }

    :deep(p) {
        margin-bottom: 10px;
        line-height: 1.4;
    }

    .entr-thumb {
        width: 270px;
        max-width: 100%;
        flex-shrink: 0;
    }

    .experience-data {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        text-align: center;
        align-items: center;
        width: calc(100% - 270px);

        .data {
            font-size: 2.812rem;
            font-weight: 600;
            margin-bottom: 6px;
        }

        .data-box {
            position: relative;
        }

        .data-sep {
            width: 1px;
            background-color: #fff;
            height: 37px;

            &:last-child {
                display: none;
            }
        }
    }

    .entr-thumb + div .experience-data {
        width: auto;
    }

		:deep(.slider-gallery){
				align-self: flex-start;
		}
}

.is-desktop {
		.entrepreneur-about {
				max-width: 630px;
				margin: auto;
		}
}

.is-mobile {
    .entrepreneur-info {

        .entrepreneur-title,
        .entrepreneur-about {
            text-align: center;
        }

        .experience-data {
            width: auto;
        }

        .entr-thumb {
            margin: auto;
        }

        .data-box {
            flex-basis: 50%;
            margin-bottom: 15px;
        }
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {

		.entrepreneur-info {
				max-width: 1300px;
				width: 100%;
				margin: auto;

				> .flex > div {
						width: 50%;
				}

				.flex > *:first-child:last-child {
						width: 100%;
				}
		}

		.experience-data,
		.entrepreneur-info .entr-thumb + div .experience-data{
				width: 500px;
				max-width: 100%;
		}

		.entrepreneur-about {
				margin: 0;
		}

}
</style>
