<template>
    <div class="page elements-preview">
        <fieldset>
            <legend>כפתורים</legend>
            <fp-button variant="primary">שליחה</fp-button>
            <fp-button variant="primary-outline">שליחה</fp-button>
            <fp-button variant="secondary">שליחה</fp-button>
            <fp-button variant="secondary-outline">שליחה</fp-button>
            <fp-icon-button symbol="caret-right-circle">כל הפרוייקטים</fp-icon-button>
        </fieldset>
        <fieldset>
            <legend>שדות</legend>
            <fp-input id="inut1" :inline="false" v-model="test1" placeholder="שם מלא כאן"></fp-input>
            <fp-input id="input2" :inline="false" type="textarea" v-model="test2" placeholder="הודעה"></fp-input>
            <fp-input id="inut3" :inline="false" type="email" v-model="test3" placeholder="דוא&quot;ל"></fp-input>
            <fp-input type="email" :inline="false" v-model="test3">
                <template #label>dsdsa</template>
            </fp-input>
        </fieldset>
        <p>{{test1}}</p>
        <p>{{test2}}</p>
        <p>{{test3}}</p>
        <fieldset>
            <legend>סמלים</legend>
            <span class="icon-block"><fp-icon symbol="accessibility" size="24" /><span>accessibility</span></span>
            <span class="icon-block"><fp-icon symbol="angel-right" size="24" /><span>angel-right</span></span>
            <span class="icon-block"><fp-icon symbol="arrow-down" size="24" /><span>arrow-down</span></span>
            <span class="icon-block"><fp-icon symbol="arrow-up" size="24" /><span>arrow-up</span></span>
            <span class="icon-block"><fp-icon symbol="buildings-3d" size="24" /><span>buildings-3d</span></span>
            <span class="icon-block"><fp-icon symbol="caret-right-circle" size="24" /><span>caret-right-circle</span></span>
            <span class="icon-block"><fp-icon symbol="chat-bubbles" size="24" /><span>chat-bubbles</span></span>
            <span class="icon-block"><fp-icon symbol="dot" size="24" /><span>dot</span></span>
            <span class="icon-block"><fp-icon symbol="download" size="24" /><span>download</span></span>
            <span class="icon-block"><fp-icon symbol="elevator" size="24" /><span>elevator</span></span>
            <span class="icon-block"><fp-icon symbol="empty-checkbox" size="24" /><span>empty-checkbox</span></span>
            <span class="icon-block"><fp-icon symbol="field-size" size="24" /><span>field-size</span></span>
            <span class="icon-block"><fp-icon symbol="frame-corner-bottom-left" size="24" /><span>frame-corner-bottom-left</span></span>
            <span class="icon-block"><fp-icon symbol="frame-corner-bottom-right" size="24" /><span>frame-corner-bottom-right</span></span>
            <span class="icon-block"><fp-icon symbol="frame-corner-top-left" size="24" /><span>frame-corner-top-left</span></span>
            <span class="icon-block"><fp-icon symbol="frame-corner-top-right" size="24" /><span>frame-corner-top-right</span></span>
            <span class="icon-block"><fp-icon symbol="globe" size="24" /><span>globe</span></span>
            <span class="icon-block"><fp-icon symbol="green-dot" size="24" /><span>green-dot</span></span>
            <span class="icon-block"><fp-icon symbol="hamburger" size="24" /><span>hamburger</span></span>
            <span class="icon-block"><fp-icon symbol="heart" size="24" /><span>heart</span></span>
            <span class="icon-block"><fp-icon symbol="heart-o" size="24" /><span>heart-o</span></span>
            <span class="icon-block"><fp-icon symbol="home" size="24" /><span>home</span></span>
            <span class="icon-block"><fp-icon symbol="info" size="24" /><span>info</span></span>
            <span class="icon-block"><fp-icon symbol="magnify-minus" size="24" /><span>magnify-minus</span></span>
            <span class="icon-block"><fp-icon symbol="magnify-plus" size="24" /><span>magnify-plus</span></span>
            <span class="icon-block"><fp-icon symbol="maximize" size="24" /><span>maximize</span></span>
            <span class="icon-block"><fp-icon symbol="minimize" size="24" /><span>minimize</span></span>
            <span class="icon-block"><fp-icon symbol="mute" size="24" /><span>mute</span></span>
            <span class="icon-block"><fp-icon symbol="open-door" size="24" /><span>open-door</span></span>
            <span class="icon-block"><fp-icon symbol="parking" size="24" /><span>parking</span></span>
            <span class="icon-block"><fp-icon symbol="phone-ring" size="24" /><span>phone-ring</span></span>
            <span class="icon-block"><fp-icon symbol="play" size="24" /><span>play</span></span>
            <span class="icon-block"><fp-icon symbol="porch" size="24" /><span>porch</span></span>
            <span class="icon-block"><fp-icon symbol="red-dot" size="24" /><span>red-dot</span></span>
            <span class="icon-block"><fp-icon symbol="restaurant" size="24" /><span>restaurant</span></span>
            <span class="icon-block"><fp-icon symbol="roller" size="24" /><span>roller</span></span>
            <span class="icon-block"><fp-icon symbol="share" size="24" /><span>share</span></span>
            <span class="icon-block"><fp-icon symbol="step-circle-empty" size="24" /><span>step-circle-empty</span></span>
            <span class="icon-block"><fp-icon symbol="swirled-arrow-left" size="24" /><span>swirled-arrow-left</span></span>
            <span class="icon-block"><fp-icon symbol="times" size="24" /><span>times</span></span>
            <span class="icon-block"><fp-icon symbol="times-circle-fill" size="24" /><span>times-circle-fill</span></span>
            <span class="icon-block"><fp-icon symbol="unmute" size="24" /><span>unmute</span></span>
            <span class="icon-block"><fp-icon symbol="whatsapp" size="24" /><span>whatsapp</span></span>
            <hr>
            <span class="icon-block"><gradiant-icon symbol="caret" :size="60"></gradiant-icon><span>caret</span></span>
            <span class="icon-block"><gradiant-icon symbol="elevator-fill" :size="60"></gradiant-icon><span>elevator-fill</span></span>
            <span class="icon-block"><gradiant-icon symbol="angel-down-primary" :size="60"/><span>angel-down-primary</span></span>
            <span class="icon-block"><gradiant-icon symbol="buildings" :size="60"/><span>buildings</span></span>
            <span class="icon-block"><gradiant-icon symbol="caret" :size="60"/><span>caret</span></span>
            <span class="icon-block"><gradiant-icon symbol="dot-fill" :size="60"/><span>dot-fill</span></span>
            <span class="icon-block"><gradiant-icon symbol="elevator-fill" :size="60"/><span>elevator-fill</span></span>
            <span class="icon-block"><gradiant-icon symbol="plus-circle-fill" :size="60"/><span>plus-circle-fill</span></span>
            <span class="icon-block"><gradiant-icon symbol="radio-fill-checked" :size="60"/><span>radio-fill-checked</span></span>
            <span class="icon-block"><gradiant-icon symbol="radio-fill-unchecked" :size="60"/><span>radio-fill-unchecked</span></span>
            <span class="icon-block"><gradiant-icon symbol="restaurant-fill" :size="60"/><span>restaurant-fill</span></span>
            <span class="icon-block"><gradiant-icon symbol="step-circle-full" :size="60"/><span>step-circle-full</span></span>
            <span class="icon-block"><gradiant-icon symbol="step-circle-full-current" :size="60"/><span>step-circle-full-current</span></span>
            <span class="icon-block"><gradiant-icon symbol="step-circle-full-current-shadowed" :size="60"/><span>step-circle-full-current-shadowed</span></span>
        </fieldset>
    </div>
</template>

<script>
import FpButton from "@/components/buttons/FpButton";
import FpInput from "@/components/FpInput";
import GradiantIcon from "@/components/GradiantIcon";
import FpIconButton from "@/components/buttons/FpIconButton";
import FpIcon from "@/components/FpIcon";
export default {
    name: "ElementsPage",
    components: {FpIcon, FpIconButton, GradiantIcon, FpButton, FpInput},
    data(){
        return {
            test1: '',
            test2: '',
            test3: '',
        }
    }
}
</script>

<style scoped>
    .icon-block {
        display: inline-flex;
        gap: 10px;
        padding: 5px 10px;
    }
</style>
