<template>
    <div class="mobile-buttons" ref="mobileButtons">
        <template v-for="(btn, btnIndex) in mobileButtons" :key="btnIndex">
            <a class="mobile-button"
               v-if="btn.link_type === 'tel'"
               :style="buttonStyle(btn)"
               :href="`tel:${btn.link}`"
            >{{btn.title}}</a>
            <a v-else-if="btn.link_type === 'contact'"
               class="mobile-button"
               :style="buttonStyle(btn)"
               href="javascript:void(0)"
               @click="$store.commit('toggleContactModal')"
            >{{btn.title}}</a>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "MobileButtons",
    computed: {
        ...mapGetters(['mobileButtons'])
    },
		watch: {
				mobileButtons(){
						this.updateHeight()
				}
		},
    methods:{
        buttonStyle(btn){
            const style = {}

            if(btn.color){
                style.backgroundColor = btn.color
            }

            if(btn.font_size){
                style.fontSize = `${btn.font_size}rem`
            }

            if(btn.font_color){
                style.color = btn.font_color
            }

            if(btn.min_height){
                style.minHeight = `${btn.min_height}px`
            }

            return style
        },
				updateHeight(){
						if(this.$refs.mobileButtons){
								this.$store.commit('mobileButtonsHeight', this.$refs.mobileButtons.clientHeight)
						}
				}
    },
		mounted() {
				this.updateHeight()
		}
}
</script>

<style scoped lang="scss">
.mobile-buttons {
    position: fixed;
    z-index: 10;
    bottom: 60px;
    width: 100%;
    display: flex;

    .mobile-button {
        color: #fff;
        flex-basis: 50%;
        text-align: center;
        padding: .2rem 1rem;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
