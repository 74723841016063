<template>
    <div class="page apartments-compare">
        <transition appear>
            <div class="apartment-blocks">
                <transition-group appear>
                    <template v-for="(aptShow, aptId) in apartmentsCompare" :key="aptId">
                        <div v-if="apartments && apartments[aptId] && aptShow" class="apartment-block" :class="{'open': apartmentContents[aptId]}" :ref="`aptBlock${aptId}`">
                            <div class="apartment-block-content">
                                <div class="ab-header">
                                    <div class="ab-title">
																				<apartment-fav-button :apt-id="aptId"></apartment-fav-button>
                                        <div>
                                            <span>{{$t('דירה')}} {{apartments[aptId].type_rooms}} {{$t('חדרים')}}</span>
                                            |
                                            <span>{{$t('דגם')}} {{apartments[aptId].type}}</span>
                                        </div>
                                    </div>
                                    <close-btn @click="removeApartment(aptId)"></close-btn>
                                </div>
                                <div class="flex flex-sm-column">
                                    <div class="apt-info">
                                        <apartment-features-list :apartment="apartments[aptId]"></apartment-features-list>
                                        <div v-if="apartments[aptId].price" class="ab-price"><span>{{$t('מחיר')}}</span><span v-html="apartments[aptId].price"></span></div>
                                    </div>
                                    <div class="apt-buttons" v-if="apartments[aptId].buttons && Array.isArray(apartments[aptId].buttons)">
																				<fp-button
																						v-for="(button, btnInd) in apartments[aptId].buttons"
																						:key="btnInd" class="active-icon"
																						:class="{active: apartmentContents[aptId] === button}"
																						expand="block"
																						variant="secondary-outline"
																						@click="contentChange(aptId, button)"
																				>{{button.label}}</fp-button>
                                    </div>
                                </div>
                            </div>
                            <transition appear>
                                <div class="apartment-block-card" v-if="apartmentContents[aptId]">
                                    <fp-icon-button class="apartment-block-card-toggle" symbol="arrow-right" @click="apartmentContents[aptId] = null"></fp-icon-button>
																		<nav v-if="apartmentContentType(aptId) === 'gallery' && apartmentContents[aptId].tabs && apartmentContents[aptId].tabs.length > 1" class="header-tabs">
																				<ul>
																						<li v-for="(tab, tabIndex) in apartmentContents[aptId].tabs" :key="tabIndex">
																								<button type="button" :class="{active: currentContentTabs[aptId] === tabIndex}" @click="changeContentTab(aptId,tabIndex)">{{tab.label}}</button>
																						</li>
																				</ul>
																		</nav>
                                    <div class="apartment-block-card-content scrollable-y">
                                        <building-specs v-if="apartmentContentType(aptId) === 'specifications'" :content="apartmentContents[aptId]"></building-specs>
                                        <div class="gallery-slides" v-else-if="apartmentContentType(aptId) === 'gallery'">
                                            <template v-if="apartmentContents[aptId].tabs[currentContentTabs[aptId]] && apartmentContents[aptId].tabs[currentContentTabs[aptId]].content">
                                                <slider-gallery :ref="`aptBlock${aptId}Gallery`" v-if="apartmentContents[aptId].tabs[currentContentTabs[aptId]].content[0].acf_fc_layout === 'gallery'"
                                                                :slides="apartmentContents[aptId].tabs[currentContentTabs[aptId]].content[0].gallery">
                                                    <template v-slot="{slide}">
                                                        <div :class="`img-${slide.image_layout}`">
                                                            <img :src="slide.url">
                                                        </div>
                                                    </template>
                                                </slider-gallery>
                                                <template v-else-if="apartmentContents[aptId].tabs[currentContentTabs[aptId]].content[0].acf_fc_layout === 'iframe'">
                                                    <iframe v-if="apartmentContents[aptId].tabs[currentContentTabs[aptId]].content[0].src" :src="apartmentContents[aptId].tabs[currentContentTabs[aptId]].content[0].src"></iframe>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </template>
                </transition-group>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CloseBtn from "@/components/buttons/CloseBtn";
import FpIconButton from "@/components/buttons/FpIconButton";
import FpButton from "@/components/buttons/FpButton";
import ApartmentFeaturesList from "@/components/ApartmentFeaturesList";
import BuildingSpecs from "@/components/BuildingSpecs";
import SliderGallery from "@/components/SliderGallery";
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton";
export default {
    name: "ApartmentsComparePage",
    components: {
				ApartmentFavButton,
				SliderGallery, BuildingSpecs, ApartmentFeaturesList, CloseBtn, FpIconButton, FpButton},
    computed:{
        apartments(){
            return this.$store.getters.products
        },
        ...mapState(['apartmentsCompare', 'currentProjectId', 'apartmentFavs']),
        ...mapGetters(['isTablet', 'isMobile', 'isDesktop'])
    },
    data(){
        return {
            apartmentContents: {},
						currentContentTab: 0,
						currentContentTabs: {}
        }
    },
    methods: {
				changeContentTab(aptId, tabIndex){
						this.currentContentTab = tabIndex
						this.currentContentTabs[aptId] = tabIndex
				},
        removeApartment(id){
            this.$store.commit('apartmentsCompareRemove', id)
            if(Object.keys(this.apartmentsCompare).filter(apt => (this.apartmentsCompare[apt])).length === 0){
                this.$router.push({name: 'apartments', params: {projectId: this.currentProjectId}})
            }
        },
        /*close(){
            this.$router.push({name: 'home'})
        }*/
        contentChange(aptId, content){
						this.currentContentTabs[aptId] = 0
            if(this.apartmentContents[aptId] && this.apartmentContents[aptId] === content){
                this.apartmentContents[aptId] = null
            }else{
                this.apartmentContents[aptId] = content
            }

            setTimeout(() => {
                const aptBlockRef = this.$refs[`aptBlock${aptId}`]
                const galleryRef = this.$refs[`aptBlock${aptId}Gallery`]
                if(aptBlockRef && galleryRef){
                    if(this.isDesktop){
                        if(aptBlockRef.style.maxHeight){
                            const innerHeight = parseInt(aptBlockRef.style.maxHeight) - 18 * 2
                            galleryRef.setSlideHeight(`${innerHeight}px`)
                        }
                    }else{
                        galleryRef.setSlideHeight('50vh')
                    }
                }
            })
        },
        apartmentContentType(aptId){
            if(this.apartmentContents[aptId] && this.apartmentContents[aptId].acf_fc_layout){
                return this.apartmentContents[aptId].acf_fc_layout
            }
            return null
        },
        calcHeight(){
            if(this.isDesktop){
                Object.entries(this.$refs).forEach(aptBlock => {
                    const block = aptBlock[1]
										if(block){
												const rect = block.getBoundingClientRect()
												block.style.maxHeight = `${rect.height}px`
										}
                })
            }
        }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next){
        next(async (vm) => {
            await vm.$store.dispatch('searchProducts')
            if(Object.keys(vm.$store.getters.apartmentsCompare).length === 0){
                await vm.$router.push({name: 'apartments', params: {projectId: vm.currentProjectId}})
            }else{
                Object.entries(vm.$store.getters.apartmentsCompare).forEach(comp => {
                    const key = comp[0]
                    //const value = comp[1]
                    vm.apartmentContents[key] = null
										vm.currentContentTabs[key] = 0
                })
            }

            vm.calcHeight()
        })
    }
}
</script>

<style scoped lang="scss">
.apartments-compare {
    padding: 0 2.5rem;

    .apartment-blocks {
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        &.v-enter-active,
        &.v-leave-active {
            transition: transform 1s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateX(100vw);
        }
    }

    .apartment-block {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        margin-bottom: 16px;
        display: flex;
        flex-flow: row nowrap;

        &.open {
						width: 1200px;
						max-width: 100%;
        }

        &.v-enter-active,
        &.v-leave-active {
            transition: transform 0.5s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateX(100vw);
        }

        .apartment-block-content {
            padding: 1.625rem 1.5rem;
        }

        .apartment-block-card {
            position: relative;
            padding: 1rem;
            border-right: solid 1px #fff;
            flex-grow: 1;

            &.v-enter-active,
            &.v-leave-active {
                transition: opacity .5s ease;
            }

            &.v-enter-from,
            &.v-leave-to {
                opacity: 0;
            }

            iframe {
								width: 100%;
								height: calc(100% - 5px);
            }

						nav.header-tabs {
								position: absolute;
								top: 0;
								right: 0;
								left: 0;
								background-color: #000;
								padding: 0 12px;

								ul {
										display: flex;
										min-height: 50px;
										align-items: center;
										gap: 10px;

										li button {
												color: #fff;
												font-size: 18px;

												&.active {
														color: var(--primary-light);
												}
										}
								}
						}

        }

        .apartment-block-card-content,
				.apartment-block-card-content .gallery-slides{
            height: 100%;
        }

        .apartment-block-card-toggle {
            position: absolute;
            right: 100%;
            background-color: #000;
            height: 50px;
            border-radius: 5px 0 0 5px;
            margin-right: 1px;
            top: 50%;
            border: solid 1px #00DBCE;
            border-right-color: #000;
            transform: translateY(-50%);
        }
    }

    .ab-header {
        display: flex;

        .close-btn {
            margin-right: auto;
        }
    }

    .apt-info {
        width: 245px;
        max-width: 100%;
    }

    .ab-title {
        flex-grow: 0;
        border-bottom: solid 1px #6c6c6c;
        padding: 5px 0;
        display: flex;
        align-items: center;
    }

    .ab-price {
        display: flex;
        justify-content: space-between;
        border: solid 1px #777878;
        border-radius: 5px;
        padding: 6px 12px;
        margin-top: 15px;
    }

    .apt-buttons {
        width: 260px;
        max-width: 100%;
        border-radius: 5px;
        border: solid 1px #5A5D5D;
        padding: 10px;
        align-self: flex-start;
        margin: 0 30px 0 0;
    }
}

.is-tablet,
.is-mobile {
    .apartments-compare {
        padding: 0;
        border-radius: 0;
        border: none;
        width: auto;

        .apartment-block {
            flex-flow: column-reverse;

						.apartment-block-card-content,
						.apartment-block-card-content .gallery-slides{
								height: 65vw;
						}
        }

        .apartment-block-content {
            display: flex;
            flex-flow: column;
            align-items: center;
        }


        .apt-buttons {
            margin: 15px 0;
            border-radius: 0;
            padding-top: 15px;
            border-right: none;
            border-left: none;
            border-bottom: none;
        }

        .ab-header {
            align-self: stretch;
            margin-bottom: 15px;
        }
    }
}

.is-mobile {
    .apartments-compare {

        .apartment-blocks {
            align-items: stretch;
        }
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.apartments-compare {
				padding: 0;

				.apartment-block {
						border-radius: 0;
						border: none;

						&.open {
								width: calc(100% - 40px);
						}
				}
		}
}
</style>
