<template>
    <ul v-if="isDesktop || isTablet" class="menu-bar-right">
        <li>
            <main-logo></main-logo>
        </li>
<!--        <li><fp-icon-button symbol="heart-o" symbol-size="23"><span v-if="showLabels">מועדפים</span></fp-icon-button></li>-->
    </ul>
</template>

<script>
import MainLogo from "@/components/MainLogo";
import {mapGetters} from "vuex";

export default {
    name: "MenuBarRight",
    components: {MainLogo},
    props: {
        showLabels: {
            default: false
        }
    },
    computed:{
        ...mapGetters(['isTablet', 'isDesktop'])
    }
}
</script>

<style scoped lang="scss">
.menu-bar-right {
    :deep(.logo-img) {
        max-height: 70px;
        padding-top: 5px;
    }
}
</style>
