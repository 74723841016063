<template>
    <button class="btn" :type="type" :class="classes"><slot name="default"></slot></button>
</template>

<script>
export default {
    name: "FpButton",
    props: {
        type: {
            default: 'button',
            type: String
        },
        variant: {
            default: 'primary-outline',
            type: String
        },
        expand: {
            default: 'none',
            type: String
        },
				size: {
						default: 'regular',
						type: String
				}
    },
    data(){
        return {
            value: null
        }
    },
		computed: {
				classes(){
						return [
							`btn-${this.variant}`,
							`expand-${this.expand}`,
							`size-${this.size}`
						]
				}
		},
    methods: {

    },
    mounted() {
    }
}
</script>
<style scoped lang="scss">
.btn {
		gap: 5px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
}
</style>
