import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import he from '@/locales/he.json'

const userSelectionLocal = localStorage.getItem('current_locale')
export const SUPPORT_LOCALES = ['en', 'he']
export const defaultLocale = 'he'
export const languages = {
    en,
    he
}
export function setupI18n(options = { locale: 'he' }) {
    const i18n = createI18n(options)
    if(SUPPORT_LOCALES.includes(userSelectionLocal) && userSelectionLocal !== defaultLocale){
        setI18nLanguage(i18n, userSelectionLocal)
    }else{
        setI18nLanguage(i18n, options.locale)
    }
    return i18n
}

const i18n = setupI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages: languages
})

export function handleLocaleChange(locale){
    const htmlTag = document.querySelector('html')
    htmlTag.setAttribute('lang', locale)
    if(locale === 'he'){
        document.dir = 'rtl'
        htmlTag.classList.add('rtl')
        htmlTag.classList.remove('ltr')
    }else{
        document.dir = 'ltr'
        htmlTag.classList.add('ltr')
        htmlTag.classList.remove('rtl')
    }
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    handleLocaleChange(locale)
}

export default i18n;
