<template>
    <img :src="src" :style="style" v-bind="$attrs" :class="this.symbol">
</template>
<script>
export default {
    name: "GradiantIcon",
    props: {
        symbol: {
            type: String
        },
        size: {
            type: Number,
            default: 24
        }
    },
    computed: {
        src(){
            return this.getImgUrl(this.symbol)
        },
        style(){
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                maxWidth: '100%'
            }
        }
    },
    methods: {
        getImgUrl(symbol) {
            const images = require.context('../assets/gradiant-icons/', false, /\.svg$/)
            return images('./' + symbol + ".svg")
        }
    }
}
</script>
<style scoped>

</style>
